import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/home';

Vue.use(Router)

const router = new Router({
	routes: [{
			path: '/',
			redirect: '/exposition'
		},
		{
			path: '/exposition',
			name: 'exposition',
			component: () => import('@/views/exposition')
		},{
			path: '/socket',
			name: 'socket',
			component: () => import('@/views/socket')
		},
		{
			path: '/xunidianchang',
			name: 'xunidianchang',
			component: () => import('@/views/xunidianchang/xunidianchang')
		},
		{
			path: '/expositionSubpage',
			name: 'expositionSubpage',
			component: () => import('@/views/expositionSubpage'),
			children:[
				{
					path: '/expositionRight', // 全国 - 车辆
					name: 'expositionRight',
					component: () => import('@/views/exposition/expositionRight'),
				},
				{
					path: '/dummy', // 全国 - 车辆
					name: 'dummy',
					component: () => import('@/views/exposition/dummy'),
				}
			]
		},
		{
			path: '',
			name: 'home',
			component: home,
			children: [{
					path: '/page1',
					name: 'page1',
					component: () => import('@/views/page1')
				},
				{
					path: '/page2',
					name: 'page2',
					component: () => import('@/views/page2')
				},
				{
					path: '/page3',
					name: 'page3',
					component: () => import('@/views/page3')
				}
			]
		},
		{
			path: '/threejs',
			name: 'threejs',
			component: () => import('@/views/three/threejs.vue')
		},
		{
			path: '/test',
			name: 'test',
			component: () => import('@/views/three/test')
		},
		{
			path: '/threejsmove',
			name: 'threejsmove',
			component: () => import('@/views/three/threejsmove')
		},
		{
			path: '/threejsmove3D',
			name: 'threejsmove3D',
			component: () => import('@/views/three/threejsmove3D')
		},
		{
			path: '/threejstest',
			name: 'threejstest',
			component: () => import('@/views/three/threejstest')
		},
		{
			path: '/Curve',
			name: 'Curve',
			component: () => import('@/views/three/Curve')
		},
		{
			path: '/threejsmp4',
			name: 'threejsmp4',
			component: () => import('@/views/three/threejsmp4')
		},
		{
			path: '/threejswalk',
			name: 'threejswalk',
			component: () => import('@/views/three/threejswalk')
		},
		{path: '/vehiclesCommon',
			name: 'vehiclesCommon',
			component: () => import('@/views/common'),
			children:[{
				path: '/vehicles1',
				name: 'vehicles1',
				component:() => import('@/views/vehicles1')
			}]
		},
		{
			path: '/vehicles',
			name: 'vehicles',
			component: () => import('@/views/vehicles'),
			children:[
				{
					path: '/vehicles-home', // 全国 - 车辆
					name: 'vehicles-home',
					component: () => import('@/views/vehicles/vehiclesCom'),
				},
				{
					path: '/heatmap-home', // 全国 - 热力图
					name: 'heatmap-home',
					component: () => import('@/views/vehicles/heatmapCom'),
				},
				{
					path: '/vehicletype-home', // 全国 - 商用车
					name: 'vehicletype-home',
					component: () => import('@/views/vehicles/vehicletypeCom'),
				},
				{
					path: '/batterypack-home', // 全国 - 电池包
					name: 'batterypack-home',
					component: () => import('@/views/vehicles/batterypackCom'),
				},
			]
		},
		{
			path: '/chonghuan',
			name: 'chonghuan',
			component: () => import('@/views/chonghuan.vue'),
			children: [
				{
					path: '/chonghuan-home', // 全国 - 充换电
					name: 'chonghuan-home',
					component: () => import('@/views/chonghuan/home'),
				},
				{
					path: '/chonghuan-monitor', // 全国 - 充换电 - 监控
					name: 'chonghuan-monitor',
					component: () => import('@/views/chonghuan/monitor'),
				},
				{
					path: '/chonghuan-station', // 全国 - 充换电 - 站/桩
					name: 'chonghuan-station',
					component: () => import('@/views/chonghuan/station'),
				},
				// {
				// 	path: '/chonghuan-pole', // 全国 - 充换电 - 桩
				// 	name: 'chonghuan-pole',
				// 	component: () => import('@/views/chonghuan/pole'),
				// },
			]
		},
		// {
		// 	path: '/mars',
		// 	name: 'chonghuan',
		// 	component: () => import('@/views/components/map/mars.vue'),
		// }
	]
})
export default router