<template>
	<div id="app">
		<router-view ref="approot" />
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
				resizeFn: null
			}
		},
		methods: {
			//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
			init1() {
				let number1 = sessionStorage.getItem('socketNumber1') ? sessionStorage.getItem('socketNumber1') : Math.floor(Math.random() * 100000000000)
				sessionStorage.setItem('socketNumber1', number1)
				// 实例化socket，这里的实例化直接赋值给wss是为了后面可以在其它的函数中也能调用websocket方法，例如：wss.close(); 完成通信后关闭WebSocket连接
				let wss = new WebSocket('wss://robot.gclme.com/websocket?connection_id=screen' + number1)
				console.log(number1)
				console.log(sessionStorage.getItem('socketNumber1'))
				console.log('wss://robot.gclme.com/websocket?connection_id=screen' + number1)
				//监听是否连接成功
				wss.onopen = () => {
					//连接成功则发送一个数据
					wss.send('连接成功');
				}

				//接听服务器发回的信息并处理展示
				wss.onmessage = (data) => {
					let obj = JSON.parse(data.data)
					if (obj.type === sessionStorage.getItem('socketNumber1')) {
						if (sessionStorage.getItem('fromScreen') == 1) {
							if(obj.text.screen1) {
								this.$router.push({
									name: obj.text.screen1.path,
									query: obj.text.screen1.query
								})
							} else {
								this.$bus.$emit('readingFn',{
									isReading: true
								})
								setTimeout(() => {
									this.$bus.$emit('readingFn',{
										isReading: false
									})
								},obj.text)
							}
							
						}
					}


					// this.init1()
				}

				//监听连接关闭事件
				wss.onclose = (err) => {
					//监听整个过程中websocket的状态
					console.log(err)
					this.init1()
					// if (wss.readyState == 3) {
					// 	this.init1()
					// }
				}

				//监听并处理error事件
				wss.onerror = function(error) {
					console.log(error)
				}
			},
			//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
			init2() {
				// 实例化socket，这里的实例化直接赋值给wss是为了后面可以在其它的函数中也能调用websocket方法，例如：wss.close(); 完成通信后关闭WebSocket连接
				let number2 =  sessionStorage.getItem('socketNumber2') ? sessionStorage.getItem('socketNumber2') : Math.floor(Math.random() * 100000000000)
				sessionStorage.setItem('socketNumber2', number2)
				// 实例化socket，这里的实例化直接赋值给wss是为了后面可以在其它的函数中也能调用websocket方法，例如：wss.close(); 完成通信后关闭WebSocket连接
				let wss = new WebSocket('wss://robot.gclme.com/websocket?connection_id=screen' + number2)

				//监听是否连接成功
				wss.onopen = () => {
					//连接成功则发送一个数据
					wss.send('连接成功');
				}

				//接听服务器发回的信息并处理展示
				wss.onmessage = (data) => {
					let obj = JSON.parse(data.data)
					console.log(obj)
					if (obj.type === sessionStorage.getItem('socketNumber2')) {
						if (sessionStorage.getItem('fromScreen') == 2) {
							if(obj.text.screen2) {
								this.$router.push({
									name: obj.text.screen2.path,
									query: obj.text.screen2.query
								})
							} else {
								this.$bus.$emit('readingFn',{
									isReading: true
								})
								setTimeout(() => {
									this.$bus.$emit('readingFn',{
										isReading: false
									})
								},obj.text)
							}
							
						}
					}


					// this.init2()
				}

				//监听连接关闭事件
				wss.onclose = (err) => {
					console.log(err)
					//监听整个过程中websocket的状态
					// if (wss.readyState == 3) {
					this.init2()
					// }
				}

				//监听并处理error事件
				wss.onerror = function(error) {
					console.log(error)
				}
			},
			//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
			init3() {
				let number3 = sessionStorage.getItem('socketNumber3') ? sessionStorage.getItem('socketNumber3') :  Math.floor(Math.random() * 100000000000)
				sessionStorage.setItem('socketNumber3', number3)
				// 实例化socket，这里的实例化直接赋值给wss是为了后面可以在其它的函数中也能调用websocket方法，例如：wss.close(); 完成通信后关闭WebSocket连接
				let wss = new WebSocket('wss://robot.gclme.com/websocket?connection_id=screen' + number3)

				//监听是否连接成功
				wss.onopen = () => {
					//连接成功则发送一个数据
					wss.send('连接成功');
				}

				//接听服务器发回的信息并处理展示
				wss.onmessage = (data) => {
					let obj = JSON.parse(data.data)
					if (obj.type === sessionStorage.getItem('socketNumber3')) {
						if (sessionStorage.getItem('fromScreen') == 3) {
							if(obj.text.screen3) {
								this.$router.push({
									name: obj.text.screen3.path,
									query: obj.text.screen3.query
								})
							} else {
								this.$bus.$emit('readingFn',{
									isReading: true
								})
								setTimeout(() => {
									this.$bus.$emit('readingFn',{
										isReading: false
									})
								},obj.text)
							}
						}
					}


					// this.init3()
				}

				//监听连接关闭事件
				wss.onclose = (err) => {
					console.log(err)
					//监听整个过程中websocket的状态
					// if (wss.readyState == 3) {
					this.init3()
					// }
				}

				//监听并处理error事件
				wss.onerror = function(error) {
					console.log(error)
				}
			},
		},
		mounted() {
			setTimeout(() => {
				if (sessionStorage.getItem('fromScreen') == 1) {
					this.init1()
				} else if (sessionStorage.getItem('fromScreen') == 2) {
					this.init2()
				} else {
					this.init3()
				}
				// this.$bus.$emit('readingFn',{
				// 	isReading: true
				// })
			}, 5000)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resizeFn);
		}
	}
</script>

<style lang="less">
	html,
	body,
	#app {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		background: #000;
	}

	html {
		font-size: 20px;
	}

	body {
		transform-origin: left top;
		background-size: 100% 100%;
	}

	#app {
		// height: 100%;
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: relative;
		overflow: hidden;
		// background: aqua;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
</style>