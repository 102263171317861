import Vue from 'vue'
import App from './App'
import router from './router'
import iView from 'iview';
import VueRouter from 'vue-router'
import VueAMap from 'vue-amap'
import echarts from 'echarts'
import ElementUI from 'element-ui';//引入ElementUI
import 'element-ui/lib/theme-chalk/index.css';
import img from './lib/img'
import utils from "./lib/utils";
import dataV from '@jiaminghi/data-view';
import VueAwesomeSwiper from 'vue-awesome-swiper' // 导入组件
import AmapVue from "@amap/amap-vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import VueAnimateNumber from 'vue-animate-number'
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}
// import ws from '@/utils/webSocketPlus.js'
// ws.connectSocketInit({
//   back: function() {}
// })
// const Socket = new WebSocket('wss://robotpre.gclme.com/websocket?connection_id=screen_1')
// Socket.onopen= function(){}

// 导入样式
import "swiper/css/swiper.css"; // swiper
import './assets/less/index.less';

AmapVue.config.version = "2.0"; // 默认2.0，这里可以不修改
AmapVue.config.key = "1c26e30abecdc9022e34e0a54fdc8d71"; // 需要在高德地图里创建 自己的key
AmapVue.config.plugins = [
  // "AMap.ToolBar",
  "AMap.MoveAnimation",
  "AMap.Autocomplete", //输入提示插件
  "AMap.PlaceSearch", //POI搜索插件
  "AMap.Scale", //右下角缩略图插件 比例尺
  "AMap.OverView", //地图鹰眼插件
  "AMap.ToolBar", //地图工具条
  "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
  "AMap.PolyEditor", //编辑 折线多，边形
  "AMap.CircleEditor", //圆形编辑器插件
  "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

// 全局注册插件
Vue.use(dataV)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(VueAMap)
Vue.use(AmapVue);
Vue.use(AMapLoader);
Vue.use(VueAnimateNumber)
Vue.prototype.$bus = new Vue()
// prototype
Vue.prototype.$echarts = function (el) {
    return echarts.init(el, null, {renderer: 'svg'})
}

VueAMap.initAMapApiLoader({
  key: '03968a44c724704dfa904f1234c03555',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0'
})


Vue.prototype.$images = img
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false;
Vue.use(iView);
Vue.use(utils)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
